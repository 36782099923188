// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  clientId: 'f18a4de0-1325-490f-97e2-8689c6ad2677',
  authority: 'https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb',
  redirectUri: 'https://workbenchops.accenture.com/', 
  SVMformURL: 'https://accentureopsworkbench.secure.force.com/osp',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
